import React from 'react';
import './Spinner.css';
import { SpinnerProps } from '../../types/Spinner';

const Spinner: React.FC<SpinnerProps> = ({
  fixed = true,
  color = '#3e85c6',
  background = '#fff',
}) => {
  const positionClass = fixed ? 'position-fixed' : 'position-absolute';
  return (
    <>
      <div
        id='spinner'
        className={`show  ${positionClass} translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center`}
        style={{ background }}
      >
        <div className='spinner-border' style={{ color }} role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    </>
  );
};
export default Spinner;
