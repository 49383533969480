import React from 'react';
import './TechnologySection.css';
import {
  BackendTech,
  FrontendTech,
  MobileTech,
} from '../AllComponents';
import Frameworks from '../Framework/Frameworks';

interface TechnologySectionProps {
  activeTab: 'mobile' | 'front-end' | 'back-end' | 'frame-work';
  onTabClick: (tab: 'mobile' | 'front-end' | 'back-end' | 'frame-work') => void;
}

const TechnologySection: React.FC<TechnologySectionProps> = React.memo(
  ({ activeTab, onTabClick }) => {

    return (
      <div className='technology-section'>
        <h2 className='mb-5 text-primary' data-wow-delay='0.1s'>
          Tech-Stack We Use in Software Development
        </h2>
        <section className='tech-navbar'>
          <ul className='nunito'>
            <li
              className={activeTab === 'mobile' ? 'active' : ''}
              onClick={() => onTabClick('mobile')}
            >
              Mobile
            </li>
            <li
              className={activeTab === 'front-end' ? 'active' : ''}
              onClick={() => onTabClick('front-end')}
            >
              Front-end
            </li>
            <li
              className={activeTab === 'back-end' ? 'active' : ''}
              onClick={() => onTabClick('back-end')}
            >
              Back-end
            </li>
            <li
              className={activeTab === 'frame-work' ? 'active' : ''}
              onClick={() => onTabClick('frame-work')}
            >
              Frameworks
            </li>
          </ul>
        </section>
        <div className='tech-content'>
          {activeTab === 'mobile' && <MobileTech />}
          {activeTab === 'front-end' && <FrontendTech />}
          {activeTab === 'back-end' && <BackendTech />}
          {activeTab === 'frame-work' && <Frameworks />}
        </div>
      </div>
    );
  }
);

export default TechnologySection;
