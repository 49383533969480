import webDevelopmentService from '../../assets/img/web-development-service.png';
import mobileAppDevelopment from '../../assets/img/mobile-app-development.png';
import iotDevelopment from '../../assets/img/iot-development.png';
import hireDevelopers from '../../assets/img/hire-developers.png';
import devopsService from '../../assets/img/devops-service.png';
import uiuxImage from '../../assets/img/uiux-icon.png';

export const serviceData = [
  {
    image: webDevelopmentService,
    title: 'Web Development',
    backgroundcolor: '#fcf7ee',
    description:
      'Mayora Infotech is a top website design and web development services company in India & USA delivering the best web development services to craft bespoke websites, web applications, web-tools, etc.',
  },
  {
    image: mobileAppDevelopment,
    title: 'Mobile App Development',
    backgroundcolor: '#edf1fe',
    description:
      'Stay ahead in the mobile-first world with feature-rich, user-friendly apps tailored for Android, iOS, or cross-platform needs. We combine innovation and technology to create mobile experiences that engage and delight your audience.',
  },
  {
    image: iotDevelopment,
    title: 'IOT Development',
    backgroundcolor: '#f0f5f4',
    description:
      'Unlock the potential of connected devices with our Internet of Things (IoT) solutions. From smart home systems to industrial IoT applications, we create secure and scalable solutions that transform how you interact with technology.',
    animation: 'fadeInUp',
  },
  {
    image: hireDevelopers,
    title: 'WebRTC',
    backgroundcolor: '#fef5f6',
    description:
      'Facilitate seamless real-time communication with WebRTC technology. Our experts build high-performing solutions for audio/video calling, live streaming, and conferencing, ensuring reliability and scalability for your business.',
  },
  {
    image: devopsService,
    title: 'DevOps',
    backgroundcolor: '#fdf5fe',
    description:
      'Accelerate your software delivery pipeline with our comprehensive DevOps services. We integrate development and operations to improve collaboration, automate workflows, and achieve faster releases with top-notch quality.',
  },
  {
    image: uiuxImage,
    title: 'UI/UX Design',
    backgroundcolor: '#fefaf5',
    description:
      'Make a lasting impression with exceptional UI/UX designs. Our creative team at Mayora Infotech delivers user-focused, visually stunning interfaces that boost engagement and elevate your digital experience.',
  },
];
