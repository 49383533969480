import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const useNavigateToPath = (path: string) => {
  const navigate = useNavigate();

  return useCallback(() => {
    navigate(path);
  }, [navigate, path]);
};

export default useNavigateToPath;
