import { FaqItem } from '../../types/FaqItem';

  export const faqData: FaqItem[] = [
    {
      id: 1,
      title: "Why Choose Mayora Infotech's Email Services?",
      content:
        "At Mayora Infotech, we provide email solutions that prioritize security, scalability, and seamless integration tailored to your business needs. Our services are designed with advanced encryption and data protection, ensuring your information is always safe. With high reliability, responsive customer support, and cost-effective packages, Mayora Infotech is dedicated to enhancing your communication efficiency and safeguarding your data. Choose us for a trusted partner in powering your business email needs.",
    },
    {
      id: 2,
      title: "Are there any hidden charges?",
      content:
        "To ensure transparency, it's important to inquire about any potential hidden charges associated with Mayora Infotech's email services. While their website emphasizes cost-effective solutions, specific details about pricing and additional fees aren't readily available. To obtain a comprehensive understanding of all potential costs, including setup fees, maintenance charges, or any other hidden expenses, it's advisable to contact their sales or customer support team directly. This will help you make an informed decision and avoid any unexpected charges in the future.",
    },
    {
      id: 3,
      title: "What are the key challenges of email marketing?",
      content:
        `<h5>Email marketing is a powerful tool for businesses, but it comes with several challenges. Here are some of the key hurdles:</h5>
         <ul>
           <li><strong>Deliverability Issues:</strong> Emails can be flagged as spam or blocked by filters, which affects their ability to reach recipients’ inboxes.</li>
           <li><strong>Audience Segmentation:</strong> Sending generic emails can lead to lower engagement. Effective segmentation requires understanding customer data and behavior.</li>
           <li><strong>Personalization and Relevance:</strong> Personalized emails are more effective, but crafting relevant content for each user segment can be time-intensive.</li>
           <li><strong>Maintaining Engagement:</strong> Engaging recipients without overwhelming them is a fine balance.</li>
           <li><strong>Content Creation:</strong> Consistently creating fresh, valuable content that meets subscribers’ needs can be challenging.</li>
           <li><strong>Adapting to Mobile Optimization:</strong> Ensuring emails are mobile-friendly is crucial.</li>
           <li><strong>Data Privacy and Compliance:</strong> Regulations like GDPR require compliance with strict data privacy standards.</li>
           <li><strong>Measuring ROI:</strong> Calculating a precise return on investment requires robust analytics.</li>
         </ul>`,
    },
  ];
  