import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css';
import { BackToTop, CopyRight, UseWowEffect } from '../AllComponents';
import { footerLinks } from '../../utils/MockData/Footer';
import { FooterLink, FooterSection } from '../../types/FooterTypes';
import { NavLink } from 'react-router-dom';

const LinkdIn = process.env.REACT_APP_MAYORA_LINKDIN ?? '#';
const Instagram = process.env.REACT_APP_MAYORA_INSTAGRAM ?? '#';
const WhatsApp = process.env.REACT_APP_MAYORA_WHATSAPP ?? '#';

const Footer: React.FC = () => {
  const [showBackToTop, setShowBackToTop] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSitemapClick = useCallback(() => {
    navigate('/sitemap');
  }, [navigate]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const socialLinks = [
    { icon: 'whatsapp', link: WhatsApp },
    { icon: 'instagram', link: Instagram },
    { icon: 'linkedin-in', link: LinkdIn },
  ];

  return (
    <>
      <div
        className='container-fluid footer py-5 wow fadeIn'
        data-wow-delay='0.2s'
      >
        <div className='container py-5'>
          <div className='row g-4'>
            {footerLinks.map(
              ({ title, links }: FooterSection, index: number) => (
                <div key={index} className='footer-item custom-col'>
                  <div className='footer-content d-flex flex-column'>
                    <h4 className='mb-4 text-dark '>{title}</h4>
                    {links.map(({ path, label }: FooterLink) => (
                      <NavLink key={label} to={path} className=''>
                        {label}
                      </NavLink>
                    ))}
                  </div>
                </div>
              )
            )}
            <div className='footer-item custom-col'>
              <div className='footer-content d-flex flex-column'>
                <h4 className='mb-4 text-dark '>Contact Info</h4>
                <span>
                  <i className='fa fa-map-marker-alt me-2'></i>
                  A-508, Sidhhivinayak Tower, Makarba, Ahmedabad, Gujarat 380051
                </span>
                <span className='mayora-mail'>
                  <i className='fas fa-envelope me-2'></i>
                  <span className='mayoragmail'>info@mayorainfotech.com</span>
                </span>
                <span>
                  <i className='fas fa-phone me-2'></i>
                  +917984687892
                </span>
                <div className='d-flex align-items-center'>
                  <i className='fas fa-share fa-lg me-2'></i>
                  {socialLinks.map(({ icon, link }) => (
                    <button
                      key={icon}
                      className='btn-square btn icon rounded-circle mx-1 border-icon icon-border-remove'
                      onClick={() =>
                        window.open(link, '_blank', 'noopener noreferrer')
                      }
                    >
                      <i className={`fab fa-${icon}`}></i>
                    </button>
                  ))}
                </div>
                <span onClick={handleSitemapClick} className='sitemap-link'>
                  <span className='bi bi-pin-map-fill'></span><span>Sitemap</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CopyRight />
      {showBackToTop && <BackToTop />}
    </>
  );
};

export default Footer;
