import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import './CopyRight.css';
import { useNavigateToPath } from '../AllComponents';

const CopyRight: React.FC = () => {
  const navigateToHome = useNavigateToPath('/') ?? '#';
  return (
    <div className='container-fluid copyright py-4'>
      <div className='container'>
        <div className='row g-4 align-items-center'>
          <div className='col-md-6 text-center text-md-start'>
            <span className='text-white text-mayora' onClick={navigateToHome}>
                <i className='fas fa-copyright text-light me-2'></i>
                Mayora Infotech, All rights reserved.
            </span>
          </div>
          <div className='col-md-6 text-center text-white footer-text '>
            Designed By{' '}
            <span className='text-mayora' onClick={navigateToHome}> 
              Mayora Infotech
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CopyRight);
