import {
  BlogsApiResponse,
  BlogsDetailsApiResponse,
  CareersApiResponse,
  CareersDetailsApiResponse,
  ContactApplicationApiResponse,
  JobApplicationApiResponse,
  ProductsApiResponse,
  ProductsDetailsApiResponse,
} from '../../types/APIs';
import ApiHandler from '../apis/Api';

export const CareerService = {
  getCareers: async (): Promise<CareersApiResponse> => {
    return await ApiHandler.get<CareersApiResponse>('career');
  },
};

export const BlogsService = {
  getBlogs: async (): Promise<BlogsApiResponse> => {
    return await ApiHandler.get<BlogsApiResponse>('blogs');
  },
};

export const BlogsDetailsService = {
  getBlogsDetails: async (id: string): Promise<BlogsDetailsApiResponse> => {
    return await ApiHandler.get<BlogsDetailsApiResponse>(
      `blogs/detail?id=${id}`
    );
  },
};

export const ProductsService = {
  getProducts: async (): Promise<ProductsApiResponse> => {
    return await ApiHandler.get<ProductsApiResponse>('products');
  },
};

export const ProductsDetailsService = {
  getProductDetails: async (
    id: any
  ): Promise<ProductsDetailsApiResponse> => {
    return await ApiHandler.get<ProductsDetailsApiResponse>(
      `products/detail?id=${id}`
    );
  },
};

export const CareerDetails = {
  getCareersDetails: async (id: number): Promise<CareersDetailsApiResponse> => {
    return await ApiHandler.get<CareersDetailsApiResponse>(
      `career/detail?id=${id}`
    );
  },

  submitApplication: async (
    formData: FormData
  ): Promise<JobApplicationApiResponse> => {
    return await ApiHandler.post<JobApplicationApiResponse, FormData>(
      'career/apply-job',
      formData
    );
  },
};

export const ContactDetails = {
  sendMessageApplication: async (
    formData: FormData
  ): Promise<ContactApplicationApiResponse> => {
    return await ApiHandler.post<ContactApplicationApiResponse, FormData>(
      'contact-us',
      formData
    );
  },
};
