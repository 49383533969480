import React from 'react';
import './Accordion.css';

const Accordion: React.FC<AccordionProps> = ({
  items,
  activeIndex,
  onAccordionClick,
}) => {
  return (
    <div className='accordion' id='accordionExample'>
      {items.map(({ id, title, content }, index) => (
        <div key={id} className='accordion-item border-0 mb-4'>
          <h2 className='accordion-header' id={`heading${id}`}>
            <button
              className={`accordion-button rounded-top ${
                activeIndex === index ? '' : 'collapsed'
              }`}
              onClick={() => onAccordionClick(index)}
              type='button'
              aria-expanded={activeIndex === index}
              aria-controls={`collapse${id}`}
            >
              {title}
            </button>
          </h2>
          <div
            id={`collapse${id}`}
            className={`accordion-collapse collapse ${
              activeIndex === index ? 'show' : ''
            }`}
            aria-labelledby={`heading${id}`}
            data-bs-parent='#accordionExample'
          >
            <div
              className='accordion-body'
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
