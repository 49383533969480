import React from 'react';
import { TechCard } from '../AllComponents';
import { mobiletechnologies } from '../../assets/Icons/Icon';

const MobileTech = React.memo(() => (
  <div className='tech-grid'>
    {mobiletechnologies.map((tech, index) => (
      <TechCard key={index} icon={tech.icon} name={tech.name} />
    ))}
  </div>
));

export default MobileTech;
