import React from 'react';
import './TechCard.css';
import { TechCardProps } from '../../types/TechCardTypes';

const TechCard: React.FC<TechCardProps> = ({ icon, name }) => {
  const cardClassName = name
    .toLowerCase()
    .replace(/\s+/g, ' ')
    .replace('.', 'dot');

  return (
    <div
      className={`tech-card wow fadeInUp ${cardClassName}`}
      data-wow-delay='0.1s'
    >
      <div className='icon-container'>
        <div className='circle'></div>
        <span className='tech-icon'>{icon}</span>
      </div>
      <h3 className='content '>{name}</h3>
    </div>
  );
};

export default TechCard;
