import React from 'react';
import './ProductBlock.css';
import { DataNotFound, useFetch } from '../AllComponents';
import { ProductsApiResponse } from '../../types/APIs';
import { ProductsService } from '../../utils/APIsService/APIsServices';
import { ProductBlockProps } from '../../types/ProductsBlockTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

const ProductBlock: React.FC<ProductBlockProps> = ({ products }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data, loading, error } = useFetch<ProductsApiResponse>(
    () => ProductsService.getProducts(),
    pathname === '/products'
  );

  const handleClick = (productId: number) => {
    navigate(`products-details/${productId}`);
  };

  const productsData = data?.data || [];

  return (
    <div className="container-fluid blog py-5 mt-4">
      <div className="container py-5">
        <section className="product-block container">
          <div className="row">
            {loading ? (
              <div className="col-12 text-center">
                <Spinner />
              </div>
            ) : error ? (
              <div className="col-12 text-center">
                <DataNotFound />
              </div>
            ) : productsData.length > 0 ? (
              productsData.map((product, index) => (
                <div
                  key={product.id}
                  className="col-12 col-sm-10 col-md-6 col-lg-4 mx-auto"
                  onClick={() => handleClick(product.id)}
                >
                  <div
                    className={`product-tile wow fadeInUp`}
                    data-wow-delay={`${index * 0.2}s`}
                  >
                    <div className="product-details">
                      <h2 className="text-secondary">{product.product_name}</h2>
                      <p className="nunito">{product.descraption}</p>
                    </div>
                    <div className="image-wrapper">
                      <img
                        src={product.product_image}
                        alt={product.product_name}
                        loading="lazy"
                        className="product-image"
                        height="200"
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 text-center">
                <DataNotFound />
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  )
}
export default ProductBlock;
