import React, { lazy, Suspense, useState } from 'react';
import Aboutimage from '../../assets/img/about.png';
import { faqData } from '../../utils/MockData/FaqData';
import { Spinner } from '../AllComponents';
const Accordion = lazy(() => import('../Accordion/Accordion'));

const FAQ: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleAccordionClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className='container-fluid FAQ bg-light overflow-hidden py-5'>
      <div className='container py-5'>
        <div className='row g-5 align-items-center'>
          <div className='col-lg-6 wow animate__pulse' data-wow-delay='0.1s'>
            <Suspense
              fallback={
                <div>
                  <Spinner />
                </div>
              }
            >
              <Accordion
                items={faqData}
                activeIndex={activeIndex}
                onAccordionClick={handleAccordionClick}
              />
            </Suspense>
          </div>
          <div className='col-lg-6 wow animate__zoomIn' data-wow-delay='0.1s'>
            <div className='FAQ-img RotateMoveRight rounded'>
              <img
                src={Aboutimage}
                className='img-fluid w-100'
                alt='About Us'
                loading='lazy'
                width='600'
                height='400'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
