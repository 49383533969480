import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BreadcrumbProps } from '../../types/BreadCrumbTypes';
import './Breadcrumb.css';
import { useNavigateToPath } from '../AllComponents';

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  title,
  currentPage,
  currentPath,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateToHome = useNavigateToPath('/') ?? (() => navigate('/'));
  if (location.pathname === '/') {
    return null;
  }

  return (
    <div className='breadcrumb-header container-fluid bg-breadcrumb'>
      <ul className='breadcrumb-animation'>
        {[...Array(10)].map((_, index) => (
          <li key={index}></li>
        ))}
      </ul>
      <div className='container text-center py-5' style={{ maxWidth: '900px' }}>
        <h1
          className='display-3 mb-4 wow text-h1 fadeInDown'
          data-wow-delay='0.1s'
        >
          {title}
        </h1>
        <ol
          className='breadcrumb justify-content-center mb-0 wow fadeInDown'
          data-wow-delay='0.3s'
        >
          <li
            className='breadcrumb-item text-decoration-none nunito'
            onClick={navigateToHome}
          >
            Home
          </li>
          {currentPath && (
            <li
              className='breadcrumb-item nunito'
              onClick={() => navigate(currentPath)}
            >
              <span className='text-decoration-none'>{currentPage}</span>
            </li>
          )}
          <li className='breadcrumb-item active nunito'>{currentPage}</li>
        </ol>
      </div>
    </div>
  );
};

export default Breadcrumb;
