import React from 'react';
import './AgileDevelopmentSection.css';
import { agileData } from '../../utils/MockData/AgileDevelopmentSection';
import { UseWowEffect } from '../AllComponents';

const AgileDevelopmentSection = () => {
  // UseWowEffect();

  return (
    <section className='section process_section'>
      <div className='container'>
        <div
          className='row justify-content-center wow fadeInDown'
          data-wow-daley='0.1s'
        >
          <div className='col-12 col-lg-9 text-center'>
            <div className='all_heading'>
              <h2 className='section_heading '>
                Agile Development <span>Life Cycle</span>
              </h2>
              <p className='subtext align-items-left text-left nunito'>
                From managing simple to complex projects and single to multiple
                projects, we follow the agile development lifecycle with various
                stages divided into sprints. The following method helps our
                organization to achieve desired productivity.
              </p>
              <div className='common_list mb-4'>
                <ul></ul>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          {agileData.map((stage: any, index: number) => (
            <div
              className='col-lg-4 col-md-6 col-12 mt-4 pt-2 wow fadeInUp'
              data-wow-delay={`${0.2 * index}s`}
              key={index}
            >
              <div
                className='card card-hover-effect border-0 rounded  h-100'
                style={{ backgroundColor: stage.color }}
              >
                <div className='card-body'>
                  <ul className='list-unstyled d-flex justify-content-between mb-0 mt-2'>
                    <li className='mb-0 fw-bold text_nub1'>
                      <h1 className='fw-bold py-2 px-3 rounded '>
                        {index + 1}
                      </h1>
                    </li>
                    <li>
                      <img
                        alt={`${stage.title}`}
                        src={stage.image}
                        width='80'
                        height='80'
                        className='img-fluid'
                        loading='lazy'
                      />
                    </li>
                  </ul>
                  <h4 className='title title_style '>{stage.title}</h4>
                  <p className='para'>{stage.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AgileDevelopmentSection;
