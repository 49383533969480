import React from 'react';
import Slider from 'react-slick';
import './Crousal.css';
import { Carsouelprops, Itemtype } from '../../types/Crousal';



const Crousal: React.FC<Carsouelprops> = ({ media }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <button className='slick-next' />,
    prevArrow: <button className='slick-prev' />,
  };

  return (
    <div className='carousel-container'>
      <Slider {...settings}>
        {media?.map((item: Itemtype, index: number) => (
          <div key={index}>
            <img
              src={item.url}
              alt='images'
              className='carousel-image'
              loading='lazy'
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Crousal;
