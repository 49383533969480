import { useEffect } from 'react';
import { WOW } from 'wowjs';

const useWowEffect = (): void => {
  useEffect(() => {
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: false,
      live: true,
    });

    wow.init();
    return () => {};
  }, []);
};

export default useWowEffect;
