import React from 'react';
import './TestimonialSection.css';
import {
  InfoHeader,
  SwiperComponent,
  UseMetaTags,
} from '../AllComponents';
import { testimonials } from '../../utils/MockData/TestimonialSection';

const TestimonialSection: React.FC = () => {
  UseMetaTags({
    title: 'Testimonials - Mayora Infotech | What Our Clients Say',
    description:
      'Read testimonials from our clients at Mayora Infotech. Discover how our IT solutions have helped businesses in Ahmedabad achieve success.',
    keywords:
      'testimonials, client feedback, IT solutions, success stories, Ahmedabad, Mayora Infotech',
    author: 'Mayora Infotech',
    language: 'English',
    ogtitle: 'Client Testimonials - Mayora Infotech',
    ogdescription:
      "Hear directly from our clients about their experiences with Mayora Infotech's IT services. Learn how we’ve made a difference in their businesses.",
    ogImage: '%PUBLIC_URL%/favicon.ico',
    ogtype: 'website',
    ogurl: 'https://mayorainfotech.com/testimonials/',
    canonical: 'https://mayorainfotech.com/testimonials/',
  });

  return (
    <div className='container-fluid testimonial py-5'>
      <div
        className='container py-5 wow animate__zoomInRight'
        data-wow-delay='0.1s'
      >
        <div className='text-center mx-auto mb-5 section-heading'>
          <h4 className=' '>Testimonial</h4>
          <InfoHeader
            title='Hear from Our Valued Clients'
            description="Our clients share their experiences and the positive impact Mayora's services have had on their businesses. From global brands to innovative startups, their stories highlight our commitment to excellence and innovation."
          />
        </div>
        <SwiperComponent testimonials={testimonials} />
      </div>
    </div>
  );
};

export default TestimonialSection;
