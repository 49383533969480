import React from 'react';
import { frontendtechnologies } from '../../assets/Icons/Icon';
import { TechCard } from '../AllComponents';

const FrontendTech = React.memo(() => (
  <div className='tech-grid'>
    {frontendtechnologies.map((tech, index) => (
      <TechCard key={index} icon={tech.icon} name={tech.name} />
    ))}
  </div>
));

export default FrontendTech;
