import React from 'react';
import { serviceData } from '../../utils/MockData/DevelopmentServices';
import './DevelopmentServices.css';

const DevelopmentServices: React.FC = React.memo(() => {
  return (
    <div className='container-fluid service py-5'>
      <div className='container py-5'>
        <div className='row g-4 justify-content-center work-item'>
          {serviceData.map(({ image, title, description, backgroundcolor }, index) => (
            <div
              className={`col-md-6 col-lg-4 mb-4 d-flex align-items-stretch wow fadeInUp`}
              data-wow-delay={`${0.2 * index}s`}
              key={index}
            >
              <div
                className='card card-effect border-0 rounded  h-100'
                style={{ backgroundColor: backgroundcolor }} 
              >
                <div className='card-body'>
                  <img
                    src={image}
                    alt={title}
                    className='card-img-top service-image mx-3 my-3'
                    loading='lazy'
                  />
                  <h4 className='card-title text-start mb-3'>{title}</h4>
                  <p className='card-text text-start'>{description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default DevelopmentServices;
