import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import logoImg from '../../assets/img/logo.png';
import './Navbar.css';

const Navbar: React.FC = () => {
  const [sticky, setSticky] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 25);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const hasNoQueryParams = location.search === '';
    setShowHeader(isHomePage && hasNoQueryParams);
  }, [location, isHomePage]);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleCollapsed = () => {
    setIsCollapsed(false);
  };

  return (
    <>
      <div
        className={`container-fluid overflow-hidden p-0 ${
          showHeader ? 'header animate-header' : ''
        }`}
      >
        <nav
          className={`navbar navbar-expand-lg fixed-top navbar-light px-4 px-lg-5  py-lg-0 ${
            sticky ? 'sticky-top' : ''
          }`}
        >
          <NavLink to='/' className='navbar-brand p-0'>
            <h1 className='display-6  align-center m-0 logo-img d-flex align-items-center ml-2'>
              <img src={logoImg} alt='mayora logo' loading='lazy' />
            </h1>
          </NavLink>
          <button
            className='navbar-toggler'
            type='button'
            onClick={handleToggle}
            data-bs-toggle='collapse'
            data-bs-target='#navbarCollapse'
          >
            <span className='fa fa-bars'></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isCollapsed ? 'show' : ''}`}
            id='navbarCollapse'
          >
            <div className='navbar-nav ms-auto py-0'>
              <NavLink
                to='/'
                onClick={handleCollapsed}
                className={`nav-item nav-link    ${
                  location.pathname === '/' ? 'active' : ''
                }`}
              >
                Home
              </NavLink>
              <NavLink
                to='/about-us'
                onClick={handleCollapsed}
                className={`nav-item nav-link  ${
                  location.pathname === '/about-us' ? 'active' : ''
                }`}
              >
                About Us
              </NavLink>
              <NavLink
                to='/services'
                onClick={handleCollapsed}
                className={`nav-item nav-link  ${
                  location.pathname === '/services' ? 'active' : ''
                }`}
              >
                Services
              </NavLink>
              <NavLink
                to='/features'
                onClick={handleCollapsed}
                className={`nav-item nav-link  ${
                  location.pathname === '/features' ? 'active' : ''
                }`}
              >
                Features
              </NavLink>
              <NavLink
                to='/blog'
                onClick={handleCollapsed}
                className={`nav-item nav-link  ${
                  location.pathname === '/blog' ? 'active' : ''
                }`}
              >
                Blog
              </NavLink>
              <NavLink
                to='/products'
                onClick={handleCollapsed}
                className={`nav-item nav-link  ${
                  location.pathname === '/products' ? 'active' : ''
                }`}
              >
                Products
              </NavLink>
              <NavLink
                to='/career'
                onClick={handleCollapsed}
                className={`nav-item nav-link  ${
                  location.pathname === '/career' ? 'active' : ''
                }`}
              >
                Career
              </NavLink>
              <NavLink
                to='/contact-us'
                onClick={handleCollapsed}
                className={`nav-item nav-link  ${
                  location.pathname === '/contact-us' ? 'active' : ''
                }`}
              >
                Contact Us
              </NavLink>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
