import React from 'react';
import './TestimonialCard.css';
import { TestimonialCardProps } from '../../types/TestimonialTypes';

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  imgSrc,
  name,
  message,
}) => {
  
  return (
    <div className='testimonial-card'>
      <div className='card-content'>
        <p className='message'>"{message}"</p>
        <div className='avatar'>
          <img src={imgSrc} alt={name} />
        </div>
        <p className='name'>{name}</p>
      </div>
    </div>
  );
};

export default TestimonialCard;
