import React, { useState } from 'react';
import './JobApplicationForm.css';
import InputField from '../InputField/InputField';
import type {
  FormData,
  JobApplicationFormProps,
} from '../../types/JobApplicationForm';
import toast, { Toaster } from 'react-hot-toast';
import { Spinner } from '../AllComponents';
import { CareerDetails } from '../../utils/APIsService/APIsServices';
const JobApplicationForm: React.FC<JobApplicationFormProps> = ({
  onClose,
  formData,
}) => {
  const [applicationData, setApplicationData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    designation: formData.position || '',
    skill: '',
    position: '',
    education: '',
    company: '',
    experience: '',
    relevant: '',
    current: '',
    expected: '',
    noticePeriod: '',
    location: '',
    coverLetter: null,
    file: null,
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;

    if (name === 'file') {
      const input = e.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        const selectedFile = input.files[0];
        setApplicationData((prevData) => ({ ...prevData, file: selectedFile }));
      }
    } else {
      setApplicationData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('designation', applicationData.designation);
    formData.append('first_name', applicationData.firstName);
    formData.append('last_name', applicationData.lastName);
    formData.append('email', applicationData.email);
    formData.append('phone', applicationData.phone);
    formData.append('skill', applicationData.skill);
    formData.append('current_position', applicationData.position);
    formData.append('education', applicationData.education);
    formData.append('company_name', applicationData.company);
    formData.append('total_experience', applicationData.experience);
    formData.append('relevant_experience', applicationData.relevant);
    formData.append('current_ctc', applicationData.current);
    formData.append('expected_ctc', applicationData.expected);
    formData.append('notice_period', applicationData.noticePeriod);
    formData.append('current_location', applicationData.location);
    formData.append('uploded_cv', applicationData.file);

    if (applicationData.coverLetter) {
      formData.append('cover_letter', applicationData.coverLetter);
    }

    try {
      const response = await CareerDetails.submitApplication(formData);
      setLoading(false);
      toast.success('Application submitted successfully!');
    } catch (error) {
      console.error('Error submitting application:', error);
      toast.error('Failed to submit the application. Please try again.');
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <div className='popup-overlay'>
      <div className='popup-content'>
        <button className='close-button ' onClick={onClose}>
          x
        </button>
        <h2 className=''>Apply for Job</h2>
        <form className='p-4' onSubmit={handleSubmit}>
          <InputField
            label='Designation'
            placeholder={applicationData.designation}
            name='designation'
            className='nunito form-control'
            readOnly
          />
          <div className='name-group'>
            <InputField
              label={
                <>
                  First Name <span style={{ color: 'red' }}>*</span>
                </>
              }
              type='text'
              name='firstName'
              value={applicationData.firstName}
              onChange={handleChange}
              className='form-control'
              title='Enter your name'
              required
            />
            <InputField
              label={
                <>
                  Last Name <span style={{ color: 'red' }}>*</span>
                </>
              }
              type='text'
              name='lastName'
              value={applicationData.lastName}
              onChange={handleChange}
              className='form-control'
              title='Enter your last name'
              required
            />
            <InputField
              label={
                <>
                  Email <span style={{ color: 'red' }}>*</span>
                </>
              }
              type='email'
              name='email'
              value={applicationData.email}
              onChange={handleChange}
              className='form-control'
              title='Enter your email'
              required
            />
            <InputField
              label={
                <>
                  Phone Number <span style={{ color: 'red' }}>*</span>
                </>
              }
              type='tel'
              name='phone'
              value={applicationData.phone}
              onChange={handleChange}
              className='form-control'
              pattern='\d{10}'
              title='max length 10 for phone number'
              required
            />
            <InputField
              label={
                <>
                  Skill/Technologies(All){' '}
                  <span style={{ color: 'red' }}>*</span>
                </>
              }
              name='skill'
              value={applicationData.skill}
              onChange={handleChange}
              className='form-control'
              title='Enter your skills'
              required
            />
            <InputField
              label={
                <>
                  Position <span style={{ color: 'red' }}>*</span>
                </>
              }
              type='text'
              name='position'
              value={applicationData.position}
              onChange={handleChange}
              className='form-control'
              title='Enter your position'
              required
            />
          </div>

          <div className='name-group'>
            <InputField
              label={
                <>
                  Education <span style={{ color: 'red' }}>*</span>
                </>
              }
              type='text'
              name='education'
              value={applicationData.education}
              onChange={handleChange}
              className='form-control'
              title='Enter your education'
              required
            />
            <InputField
              label={
                <>
                  Company Name <span style={{ color: 'red' }}>*</span>
                </>
              }
              type='text'
              name='company'
              value={applicationData.company}
              onChange={handleChange}
              className='form-control'
              title='Enter your comapny name'
              required
            />
            <InputField
              label={
                <>
                  Total Experience <span style={{ color: 'red' }}>*</span>
                </>
              }
              type='text'
              name='experience'
              placeholder='e.g (1 or 1.5 year)'
              value={applicationData.experience}
              onChange={handleChange}
              className='form-control'
              pattern='^([1-9]?\d)(\.\d{1})?$'
              title='The total experience must be an number.'
              required
            />
            <InputField
              label={
                <>
                  Relevant Experience <span style={{ color: 'red' }}>*</span>
                </>
              }
              type='text'
              name='relevant'
              placeholder='e.g (1 or 1.5 year)'
              value={applicationData.relevant}
              onChange={handleChange}
              className='form-control'
              pattern='^([1-9]?\d)(\.\d{1})?$'
              title='The relevant experience must be an number.'
              required
            />
            <InputField
              label={
                <>
                  Current CTC <span style={{ color: 'red' }}>*</span>
                </>
              }
              type='text'
              name='current'
              placeholder='e.g (4.5)'
              value={applicationData.current}
              onChange={handleChange}
              className='form-control'
              pattern='^(?:[1-9]|[1-9]\d{1,5}|10000000)(?:\.\d{1})?$'
              title='The current ctc must be in correct formate.'
              required
            />
            <InputField
              label={
                <>
                  Expected CTC <span style={{ color: 'red' }}>*</span>
                </>
              }
              type='text'
              name='expected'
              placeholder='e.g (4.5)'
              value={applicationData.expected}
              onChange={handleChange}
              className='form-control'
              pattern='^(?:[1-9]|[1-9]\d{1,5}|10000000)(?:\.\d{1})?$'
              title='The expected ctc must be in correct formate.'
              required
            />
            <InputField
              label={
                <>
                  Notice Period <span style={{ color: 'red' }}>*</span>
                </>
              }
              type='text'
              name='noticePeriod'
              value={applicationData.noticePeriod}
              onChange={handleChange}
              className='form-control'
              title='Enter notice period'
              pattern='^(?:[1-9][0-9]{0,4})(?:\s*(months?|weeks?|years?))?$'
              placeholder='e.g., 30 days, 2 months, 1 week'
              required
            />
            <InputField
              label={
                <>
                  Current Location <span style={{ color: 'red' }}>*</span>
                </>
              }
              type='text'
              name='location'
              value={applicationData.location}
              onChange={handleChange}
              className='form-control'
              title='Enter your location'
              required
            />
            <div className='form-group'>
              <label htmlFor='cvFile'>
                Upload CV <span style={{ color: 'red' }}>*</span>
              </label>
              <InputField
                type='file'
                name='uploded_cv'
                accept='application/pdf'
                onChange={(e) =>
                  setApplicationData((prev) => ({
                    ...prev,
                    file: e.target.files?.[0] || null,
                  }))
                }
                className='form-control '
                title='Upload your CV'
                required
              />
            </div>
            <div className='form-group'>
              <label htmlFor='cvFile'>Cover Letter </label>
              <InputField
                type='file'
                name='cover_letter'
                accept='application/pdf'
                onChange={(e) =>
                  setApplicationData((prev) => ({
                    ...prev,
                    coverLetter: e.target.files?.[0] || null,
                  }))
                }
                className='form-control '
                title='Upload your cover letter'
              />
            </div>
          </div>
          <button
            type='submit'
            className='btn icon con-border-remove btn-shiny nunito btn-loader'
            disabled={loading}
          >
            {loading ? (
              <Spinner fixed={false} color='#fff' background='transparant' />
            ) : (
              'Submit Application'
            )}
          </button>
        </form>
        <div className='toaster-div'>
          <Toaster position='top-center' reverseOrder={false} />
        </div>
      </div>
    </div>
  );
};

export default JobApplicationForm;
