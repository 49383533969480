import concept from '../../assets/img/concept-image.png';
import design from '../../assets/img/design-image.png';
import development from '../../assets/img/development-image.png';
import testing from '../../assets/img/testing-image.png';
import deployment from '../../assets/img/deployment-image.png';
import review from '../../assets/img/maintance-image.png';

export const agileData = [
  {
    title: 'Concept',
    image: concept,
    description:
      'We analyze your vision, define project objectives, and create a roadmap to turn your ideas into reality with clear goals and priorities.',
    delay: '0.1s',
    color: '#F1F8FF',
  },
  {
    title: 'Design',
    image: design,
    description:
      "Our expert team crafts wireframes and prototypes, focusing on user-friendly interfaces that align with your brand and enhance user engagement.",
    delay: '0.1s',
    color: '#F1F8FF',
  },
  {
    title: 'Development',
    image: development,
    description:
      "The final product is deployed seamlessly, backed by extensive checks to ensure smooth performance and compatibility across platforms.",
    delay: '0.1s',
    color: '#F1F8FF',
  },
  {
    title: 'Testing',
    image: testing,
    description:
      "This is the stage where our project team works closely with the stakeholders to gather all the required details. Here we understand the project's primary concept to give it the right push during the inception stage",
    delay: '0.1s',
    color: '#F1F8FF',
  },
  {
    title: 'Deployment',
    image: deployment,
    description:
      "TThe final product is deployed seamlessly, backed by extensive checks to ensure smooth performance and compatibility across platforms.",
    delay: '0.1s',
    color: '#F1F8FF',
  },
  {
    title: 'Maintenance',
    image: review,
    description:
      "We provide long-term support, regular updates, and performance optimization to keep your solution up-to-date and running efficiently.",
    delay: '0.1s',
    color: '#F1F8FF',
  },
];
