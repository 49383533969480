import React, { FC } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import { TestimonialCard } from '../AllComponents';

const SwiperComponent: FC<SwiperProps> = ({ testimonials }) => {
  
  const setting = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 620,  
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  
  return (
    <div className='slider-container'>
      <Slider {...setting}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className='testimonial-slider'>
            <TestimonialCard {...testimonial} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SwiperComponent;
