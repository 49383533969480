import React, { useEffect, useState } from 'react';
import { facts } from '../../utils/MockData/FunFactsSection';
import './FunFactsSection.css';
import bglines from '../../assets/img/bg_lines.svg';
import WOW from 'wowjs';

const FunFactsSection = () => {
  const [counts, setCounts] = useState(facts.map(() => 0));

  useEffect(() => {
    new WOW.WOW().init();
    const animationDelay = 3000;
    const startCounting = () => {
      const intervalIds = facts.map((fact, index) => {
        const targetValue = parseInt(fact.value, 10);
        return setInterval(() => {
          setCounts((prevCounts) => {
            const newCounts = [...prevCounts];
            if (newCounts[index] < targetValue) {
              newCounts[index] += Math.ceil(targetValue / 100);
            } else {
              clearInterval(intervalIds[index]);
              newCounts[index] = targetValue;
            }
            return newCounts;
          });
        }, 30);
      });
    };

    const countingTimeout = setTimeout(startCounting, animationDelay);

    return () => {
      facts.forEach((_, index) => clearInterval(index));
      clearTimeout(countingTimeout);
    };
  }, []);

  return (
    <>
      <section
        className='fun-facts-area ptb-100 wow fadeInUp mb-5'
        data-wow-delay='0.3s'
      >
        <img
          src={bglines}
          alt='Background Lines'
          className='fun-facts-bg'
          loading='lazy'
        />
        <div className='container'>
          <div className='row'>
            {facts.map((fact, index) => (
              <>
                <div className='col-lg-3 col-sm-6' key={index}>
                  <div className='single-fun-facts'>
                    <h3 className=''>
                      <span className='odometer'>{counts[index]}</span>
                      {fact.label === 'Project Completed' ||
                      fact.label === 'Expert Members' ? (
                        <span className='sign-icon'>+</span>
                      ) : fact.label === 'Client Satisfaction' ? (
                        <span className='sign-icon  '>%</span>
                      ) : null}
                    </h3>
                    <p className='nunito'>{fact.label}</p>
                    <div className='back-text'>{fact.backText}</div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default FunFactsSection;
