import React from 'react';
import { InfoHeaderProps } from '../../types/InfoHesder';

const InfoHeader: React.FC<InfoHeaderProps> = ({
  title,
  description,
  titleClass = 'text-primary mb-1',
  descriptionClass = 'nunito',
  containerClass = 'container',
  rowClass = 'row align-items-center text-center wow fadeInUp',
  animationDelay = '0.1s',
}) => {
  return (
    <div className={containerClass}>
      <div className={rowClass} data-wow-delay={animationDelay}>
        <h1 className={titleClass}>{title}</h1>
        <p className={descriptionClass}>{description}</p>
      </div>
    </div>
  );
};

export default InfoHeader;
