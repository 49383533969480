import dashboardFinal from '../../assets/img/dashboard-final.webp';
export const tabs = [
  {
    title: 'Strategy & Business',
    description:
      'Align goals with market trends for sustainable growth and efficiency.',
    image: dashboardFinal,
  },
  {
    title: 'Product Development',
    description:
      'Develop innovative products balancing quality, cost, and user satisfaction.',
    image: dashboardFinal,
  },
  {
    title: 'Futures Research',
    description:
      'Explore emerging trends to forecast opportunities and drive innovation.',
    image: dashboardFinal,
  },
];
