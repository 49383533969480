import React from 'react';

import { 
  RiAngularjsLine, RiTailwindCssFill 
} from 'react-icons/ri';

import { 
  FaReact, FaVuejs, FaPhp, FaCss3Alt, FaNodeJs, FaPython, 
  FaJava, FaSwift, FaLaravel, FaRegHospital, FaBuilding, 
  FaPlane, FaMedkit, FaShippingFast, 
  FaLaptopHouse, 
  FaApple
} from 'react-icons/fa';

import { 
  SiFlutter, SiTypescript, SiDjango, SiCakephp, SiXamarin 
} from 'react-icons/si';

import { 
  DiHtml5, DiCodeigniter, DiHtml5Multimedia 
} from 'react-icons/di';

import { 
  IoLogoJavascript, IoLogoAndroid, IoLogoIonic 
} from 'react-icons/io';

import { 
  PiWatchFill 
} from 'react-icons/pi';

import { 
  BiSolidTShirt, BiSolidBank 
} from 'react-icons/bi';

import { 
  MdCastForEducation 
} from 'react-icons/md';

import { 
  AiOutlineDotNet 
} from 'react-icons/ai';
import { TbBrandReactNative } from "react-icons/tb";
import { FaCartShopping } from 'react-icons/fa6';

export const frontendtechnologies = [
  { icon: <RiAngularjsLine />, name: 'AngularJS' },
  { icon: <FaReact />, name: 'ReactJS' },
  { icon: <FaVuejs />, name: 'VueJS' },
  { icon: <IoLogoJavascript />, name: 'JavaScript' },
  { icon: <SiTypescript />, name: 'TypeScript' },
  { icon: <DiHtml5 />, name: 'HTML5' },
  { icon: <FaCss3Alt />, name: 'CSS3' },
  { icon: <RiTailwindCssFill />, name: 'Tailwind CSS' },
  { icon: <SiFlutter />, name: 'Flutter' },
];

export const backendtechnologies = [
  { icon: <AiOutlineDotNet />, name: '.NET' },
  { icon: <FaPhp />, name: 'PHP' },
  { icon: <FaNodeJs />, name: 'Nodejs' },
  { icon: <FaPython />, name: 'Python' },
  { icon: <FaJava />, name: 'Java' },
];

export const mobiletechnologies = [
  { icon: <FaApple />, name: 'iOS' },
  { icon: <IoLogoAndroid />, name: 'Android' },
  { icon: <FaSwift />, name: 'Swift' },
  { icon: <SiFlutter />, name: 'Flutter' },
  { icon: <IoLogoIonic />, name: 'Ionic' },
  { icon: <TbBrandReactNative /> , name: 'React Native' },
  { icon: <SiXamarin />, name: 'Xamarin' },
];

export const frameworks = [
  { icon: <FaLaravel />, name: 'Laravel' },
  { icon: <DiCodeigniter />, name: 'CodeIgniter' },
  { icon: <SiDjango />, name: 'Django' },
  { icon: <SiCakephp />, name: 'CakePHP' },
];

export const industries = [
  { icon: <BiSolidTShirt className="text-primary" />, name: 'Lifestyle' },
  { icon: <FaCartShopping className="text-success" />, name: 'Retail & E-commerce' },
  { icon: <FaShippingFast className="text-warning" />, name: 'Logistics & Transportation' },
  { icon: <FaLaptopHouse className="text-info" />, name: 'Smart Technologies' },
  { icon: <FaRegHospital className="text-danger" />, name: 'Healthcare' },
  { icon: <MdCastForEducation className="text-dark" />, name: 'Education & E-learning' },
  { icon: <BiSolidBank className="text-info" />, name: 'Banking & Financial' },
  { icon: <DiHtml5Multimedia className="text-warning" />, name: 'Media & Entertainment' },
  { icon: <FaBuilding className="text-secondary" />, name: 'Real Estate' },
  { icon: <PiWatchFill className="text-danger" />, name: 'Wearables' },
  { icon: <FaMedkit className="text-success" />, name: 'Hospitality' },
  { icon: <FaPlane className="text-dark" />, name: 'Travel & Tourism' },
];
