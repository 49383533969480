import React from 'react';
import './ProductsDetailsFeature.css';
import { adminAuthenticationAndUserManagement } from '../../utils/MockData/ProductsDetailsFeatures';

const ProductsDetailsFeatures: React.FC<any> = ({ productsFeatures }) => {
  return (
    <div className='container py-5'>
      <section className='mb-4'>
        <p
          dangerouslySetInnerHTML={{
            __html: productsFeatures?.data.front_end_screens,
          }}
          className='products-details-li'
        />
      </section>
      <section className='mb-4'>
        <p
          dangerouslySetInnerHTML={{
            __html: productsFeatures?.data.backend_screens,
          }}
          className='products-details-li'
        />
      </section>
      <section className='mb-4'>
        <p
          dangerouslySetInnerHTML={{
            __html: productsFeatures?.data.app_credential,
          }}
          className='products-details-li'
        />
      </section>
      <section className='mb-4'>
        <p
          dangerouslySetInnerHTML={{
            __html: productsFeatures?.data.what_will_you_get,
          }}
          className='products-details-li'
        />
      </section>
      <section className='mb-4'>
            <p
              dangerouslySetInnerHTML={{
                __html: productsFeatures?.data.refund_policy,
              }}
              className='products-details-li'
            />
      </section>
    </div>
  );
};
export default ProductsDetailsFeatures;
